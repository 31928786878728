<template>
  <v-app>
    <v-container>
      <v-card class="my-4" elevation="2">
        <v-card-title>
          <span class="font-weight-bold text-h5">Financial Statement Preparation</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <p v-if="loadingMessage" style="color: red;">{{ loadingMessage }}</p>
          <v-row>
            <v-col cols="12">
              <v-btn @click="toggleSection('Standalone FS')" color="primary" class='mr-2'>Standalone</v-btn>
              <v-btn @click="toggleSection('Consolidation')" color="primary" class='mr-2'>Consolidation</v-btn>
            </v-col>
            <v-col cols="12" v-if="section === 'Standalone FS'">
              <v-file-input v-model="FSFiles" label="Upload FS Files" outlined dense multiple></v-file-input>
              <v-file-input v-model="TBFiles" label="Upload TB Files" outlined dense multiple></v-file-input>
              <v-file-input v-model="AEFiles" label="Upload AE Files" outlined dense multiple></v-file-input>
              <v-btn @click="updateFS" color="success" class="mr-2" :disabled="isLoading">Update FS</v-btn>
              <v-btn @click="carryForwardFS" color="success" :disabled="isLoading">Carry Forward FS</v-btn>
            </v-col>
            <v-col cols="12" v-if="section === 'Consolidation'">
              <v-file-input v-model="SFSFiles" label="Upload SFS Files for Consolidation" outlined dense multiple></v-file-input>
              <v-file-input v-model="CFSFiles" label="Upload consolidated CFS" outlined dense multiple></v-file-input>
              <v-file-input v-model="CAEFiles" label="Upload Consolidation AE Files" outlined dense multiple></v-file-input>
              <v-btn @click="startConsol" color="success" class='mr-2' :disabled="isLoading">Start Consolidation</v-btn>
            </v-col>
            <v-col cols="12">
              <div class="d-flex justify-end">
                <v-btn @click="downloadAllFiles" color="primary">download all Files</v-btn>
                <span class="mx-2"></span>
                <v-btn @click="fetchFileList" color="primary">Refresh</v-btn>
              </div>
            </v-col>       
            <v-progress-linear
              v-if="isLoading"
              indeterminate
              color="primary"
            ></v-progress-linear>            
            <!-- S3 Files Table -->
            <v-data-table
              v-if="fileList && fileList.length > 0"
              :headers="fileHeaders"
              :items="fileList"
              :items-per-page="5"
              :sort-by="['createdDate']"
              :sort-desc="[true]"
              class="mt-4"
              :height="tableHeight"
              style="width: 100%;">

              <!-- Correct Slot Syntax -->
              <template slot="item.index" slot-scope="{ index }">
                {{ index + 1 }}
              </template>

              <template slot="item.name" slot-scope="{ item }">
                {{ item.name }}
              </template>

              <template slot="item.createdDate" slot-scope="{ item }">
                {{ item.createdDate }}
              </template>

              <template slot="item.createdBy" slot-scope="{ item }">
                {{ item.createdBy }}
              </template>

              <template slot="item.actions" slot-scope="{ item }">
                <v-btn color="blue" @click="downloadFile(item.url)" text>Download</v-btn>
                <v-btn color="blue" @click="viewFile(item.url)" text>View</v-btn>
                <v-btn color="blue" @click="deleteFile(item.name)" text>Delete</v-btn>
              </template>
            </v-data-table>
            <p v-else class="text-center mt-4">No files found.</p>
          </v-row>
          <v-dialog v-model="spreadsheetDialog" max-width="90%">
            <v-card>
              <v-card-title>
                <span class="headline">Excel Viewer</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="spreadsheetDialog = false"><v-icon>mdi-close</v-icon></v-btn>
              </v-card-title>
              <v-card-text>
                <ejs-spreadsheet v-if="spreadsheetDialog" ref="spreadsheet" :openUrl="openUrl" :allowEditing="true"></ejs-spreadsheet>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="saveSpreadsheet">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-snackbar v-model="snackbar" :timeout="3000">{{ snackbarText }}</v-snackbar> 
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import { SpreadsheetComponent } from "@syncfusion/ej2-vue-spreadsheet"

export default {
  components: {
    "ejs-spreadsheet": SpreadsheetComponent,
  },  
  data() {
    return {
      section: '',
      clientId: '',
      FSFiles: [], 
      TBFiles: [], 
      AEFiles: [], 
      fileList: [],
      SFSFiles: [],
      CFSFiles: [],
      CAEFiles: [],
      isLoading: false,
      snackbar: false,
      snackbarText: '',
      spreadsheetDialog: false,
      selectedFile: null,
      openUrl: "",      
      fileHeaders: [
        { text: 'No.', value: 'index', sortable: false },
        { text: 'Automated Financial Statement File name', value: 'name' },
        { text: 'Created Date', value: 'createdDate', sortable: true },
        { text: 'Created By', value: 'createdBy' },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
      ],
      tableHeight: '500px',
    };
  },
  mounted() {
    this.clientId = this.$store.state.client;
    this.fetchFileList();
  },
  methods: {
    toggleSection(selectedSection) {
      this.section = selectedSection;
    },
    createFormData(files) {
      let formData = new FormData();
      
      for (let key in files) {
        if (Array.isArray(files[key])) {  // Ensure it's an array before looping
          files[key].forEach(file => {
            formData.append(key, file);
          });
        }
      }
      
      formData.append('clientId', this.clientId);
      return formData;
    },
    formatDate(dateString) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const date = new Date(dateString);
    const formattedDate = `${("0" + date.getDate()).slice(-2)}-${months[date.getMonth()]}-${date.getFullYear()}`;
    const time = date.toTimeString().split(' ')[0];  // Extracts the 'HH:MM:SS' format
    return `${formattedDate} ${time}`;
    },
    fetchFileList() {
      this.isLoading = true;
      this.axios.get("/v2/financialclose/masters/financialstatement/files", { params: { clientId: this.clientId } })
        .then(response => {
          console.log("API Response:", response.data);
          if (!response.data || !Array.isArray(response.data)) {
            console.error("Unexpected API response:", response.data);
            this.fileList = []; //  Prevents `undefined` issues
            return;
          }
          this.isLoading = false;
          this.fileList = response.data.map((file, index) => ({
            index: index + 1,
            name: file.name || "N/A",
            createdDate: file.createdDate ? this.formatDate(file.createdDate) : "N/A",
            url: file.url || "",
          }));
          console.log("Processed File List:", this.fileList);
        })
        .catch(error => {
          console.error('Error fetching files:', error);
          this.$toast.error("Failed to fetch files. Please try again.");
          this.fileList = []; //  Prevents further errors
          this.isLoading = false;
        });
    },
    downloadFile(url) {
      if(url){
        window.open(url, '_blank');
      } else {
        this.$toast.error("File not found.");
      }
    },
    deleteFile(fileName) {
      this.axios.post("/v2/financialclose/masters/financialstatement/deleteFile", {
          clientId: this.clientId,
          fileName: fileName
      })
      .then(response => {
          this.snackbarText = "File deleted successfully";
          this.snackbar = true;        
          console.log("File deleted:", response.data);
          this.fetchFileList();
      })
      .catch(error => {
          this.snackbarText = "Failed to delete file";
          this.snackbar = true;
          console.error("Error deleting file:", error);
      });
    },
    downloadAllFiles() {
      console.log("Downloading all files...");
      this.isLoading = true;

      this.axios.get("/v2/financialclose/masters/financialstatement/downloadAll", {
        params: { clientId: this.clientId },
        responseType: "blob",  // Important to handle binary files
      })
      .then(response => {
        console.log("Download Response:", response);
        
        // Create a Blob from the response
        const blob = new Blob([response.data], { type: "application/zip" });
        const link = document.createElement("a");

        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", "all_files.zip");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.fetchFileList();
        this.isLoading = false;
      })
      .catch(error => {
        console.error("Error downloading all files:", error);
        this.$toast.error("Error downloading files.");
        this.fetchFileList();
        this.isLoading = false;
      })
      .finally(() => {
        this.fetchFileList();
        this.isLoading = false;
      });
    },
    updateProgress(status) {
      this.loadingMessage = status;
      let loader = this.$loading.show({
        canCancel: false, // disables cancel button
        color: '#000',
        loader: 'dots'
      });
      return loader;
    },
    updateFS() {
      if (!this.FSFiles.length || (!this.TBFiles.length && !this.AEFiles.length)) {
        this.$toast.info('Please upload the necessary files.');
        return;
      }
      this.isLoading = true;
      const formData = this.createFormData({ FSFiles: this.FSFiles, TBFiles: this.TBFiles, AEFiles: this.AEFiles });
      formData.append('func', 'updateFS');
      this.axios.post("/v2/financialclose/masters/financialstatement/upload", formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(() => {
        this.$toast.success('Financial statements updated successfully.');
        this.fetchFileList();
        this.isLoading = false;
      }).catch(error => {
        console.error("Failed to update financial statements:", error);
        this.$toast.error("Failed to update financial statements.");
        this.fetchFileList();
        this.isLoading = false;
      }).finally(() => {
        this.fetchFileList();
        this.isLoading = false;
      });
    },
    carryForwardFS() {
      if (!this.FSFiles.length || (!this.TBFiles.length && !this.AEFiles.length)) {
        this.$toast.info('Please upload the necessary files.');
        return;
      }
      this.isLoading = true;
      const formData = this.createFormData({ FSFiles: this.FSFiles, TBFiles: this.TBFiles, AEFiles: this.AEFiles });
      formData.append('func', 'CarryForwardFS');
      this.axios.post("/v2/financialclose/masters/financialstatement/carryforward", formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(() => {
        this.$toast.success('Financial statements carried forward successfully.');
        this.fetchFileList();
        this.isLoading = false;
      }).catch(error => {
        console.error("Failed to carry forward financial statements:", error);
        this.$toast.error("Failed to carry forward financial statements.");
        this.fetchFileList();
        this.isLoading = false;
      }).
      finally(() => {
        this.fetchFileList();
        this.isLoading = false;
      });
    },
    async viewFile(url) {
        try {
            if (!url) {
                this.$toast.error("Invalid file URL");
                return;
            }

            console.log("Fetching file from:", url);

            // Fetch the file from S3
            const response = await fetch(url);

            if (!response.ok) {
                throw new Error(`Failed to fetch file from S3. Status: ${response.status}`);
            }

            // Convert response to Blob
            const fileBlob = await response.blob();

            // Convert Blob to File (Syncfusion requires a File object)
            const file = new File([fileBlob], "temp.xlsx", {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });

            console.log("File successfully loaded:", file);

            // Open the file in Syncfusion
            this.spreadsheetDialog = true;
            setTimeout(() => {
                this.$refs.spreadsheet.open({ file });
            }, 500);

        } catch (error) {
            console.error("Error in viewFile():", error.message || error);
            this.$toast.error("Error opening file. Please try again.");
        }
    },
    async saveSpreadsheet() {
      const spreadsheet = this.$refs.spreadsheet;
      const fileName = this.selectedFile;

      spreadsheet.saveAsBlob("xlsx").then(async (blob) => {
        let formData = new FormData();
        formData.append("file", blob, fileName);
        formData.append("clientId", this.clientId);
        formData.append("fileName", fileName);

        try {
          await this.axios.post("/v2/financialclose/masters/financialstatement/uploadEditedFile", formData);
          this.$toast.success("File saved successfully!");
          this.spreadsheetDialog = false;
        } catch (error) {
          console.error("Error saving file:", error);
          this.$toast.error("Failed to save file.");
        }
      });
    },
    startConsol() {
      if (!this.CFSFiles.length || (!this.SFSFiles.length && !this.CAEFiles.length)) {
        this.$toast.info('Please upload the necessary CFS or (SFS or CAE)  files.');
        return;
      }
      this.isLoading = true;
      const formData = this.createFormData({ CFSFiles: this.CFSFiles, SFSFiles: this.SFSFiles, CAEFiles: this.CAEFiles });
      formData.append('func', 'startConsolidation');
      this.axios.post('/v2/financialclose/masters/financialstatement/consolidate', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(() => {
        this.$toast.success('Consolidation process started successfully.');
        this.fetchFileList();
        this.isLoading = false;
      }).catch(error => {
        console.error("Failed to start consolidation:", error);
        this.$toast.error("Failed to start consolidation.");
        this.isLoading = false;
      }).
      finally(() => {
        this.isLoading = false;
      });
    }
  }
}
</script>

<style scoped>
body {
  min-height: 100vh;
  overflow-y: auto;
}
.v-card {
  overflow-y: auto;
  max-height: 90vh;  /* This limits the card's height to 90% of the viewport height */
}

/* Optional: Styling the scrollbar for WebKit browsers */
.v-card::-webkit-scrollbar {
  width: 8px; /* Scrollbar width */
}

.v-card::-webkit-scrollbar-thumb {
  background-color: darkgrey; /* Scrollbar thumb color */
  border-radius: 4px;
}

.v-card::-webkit-scrollbar-track {
  background: #f1f1f1; /* Scrollbar track color */
}
.v-data-table {
  width: 90%;
  overflow-x: auto;
}
.v-btn { min-width: 150px; }
.v-card-title { align-items: center; }
.v-text-field { max-width: 400px; }
.header-actions-style {
  padding-left: 50%; /* Adjust as necessary */
}
::v-deep .v-data-table-header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 5;
}
</style>
